/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import _ from 'lodash';
import {createSelector} from 'reselect';
import {isUserReadOnly} from 'containers/User/UserState';
import {getGridSelector, getUrlFilterParam} from 'components/Grid/GridSelectors';
import {gridSettings} from './VenLibraryConfig';
import {getAllResourcesObject} from 'containers/Selector/SelectorUtils';
import {filterToQuery} from 'containers/Selector/GridFilter/GridFilterUtils';

export default {
  libraries(state = [], action) {
    switch (action.type) {
      case 'VEN_LIBRARIES_GET_LIST':
        return action.data.list;
      default:
        return state;
    }
  },

  libraryCount(state = {}, action) {
    switch (action.type) {
      case 'VEN_LIBRARIES_GET_LIST':
        return action.data.count;
      default:
        return state;
    }
  },
};

export const getVenLibraries = state => state.ven.libraries;
export const getVenLibraryCount = state => state.ven.libraryCount;

export const getSortedVenLibraries = createSelector([getVenLibraries], venLibraries => {
  return [...venLibraries].sort((a, b) => {
    return a.default === true && b.default === false
      ? -1
      : a.default === false && b.default === true
      ? 1
      : a.release < b.release
      ? 1
      : -1;
  });
});

export const getAllVens = createSelector([getSortedVenLibraries, isUserReadOnly], (vens, userIsReadOnly) =>
  vens.reduce((result, ven) => {
    // IF Windows VENS need to display OS Version in future, remove this line and the 2 if statements around this
    // variable and push all images to the results array EYE-54638
    const windowsReleases = {};

    ven.images.forEach(image => {
      if (!windowsReleases[image.filename]) {
        const key = `${image.href} - ${image.architecture} - ${image.major_version}`;

        if (!result.some(row => row.key === key)) {
          result.push({
            downloadPath: `api/v2${image.href}`,
            key: `${image.href} - ${image.architecture} - ${image.major_version}`,
            data: image,
            release: image.release,
            filename: image.filename,
            distribution: image.distribution,
            architecture: image.architecture,
            osVersion: image.distribution === 'Windows' ? null : image.major_version.toString(),
            default: ven.default,
            readOnly: userIsReadOnly,
            ven_type:
              image.ven_types?.includes('server') && image.ven_types.includes('endpoint')
                ? intl('VEN.ServerEndpoint')
                : image.ven_types?.includes('endpoint')
                ? intl('VEN.EndpointOnly')
                : intl('VEN.ServerOnly'),
          });
        }
      }

      if (image.distribution === 'Windows') {
        windowsReleases[image.filename] = true;
      }
    });

    return result;
  }, []),
);

export const getGridSettings = createSelector(isUserReadOnly, userIsReadOnly => {
  const columns = {...gridSettings.columns};

  columns.download.disabled = userIsReadOnly;

  return {...gridSettings, columns};
});

export const categories = createSelector([getAllVens], allRows => {
  return [
    {
      id: 'release',
      name: intl('Workloads.Release'),
      resources: {release: {statics: Object.keys(_.groupBy(allRows, v => v.release ?? intl('Common.None')))}},
    },
    {
      id: 'filename',
      name: intl('VEN.Filename'),
      resources: {filename: {statics: Object.keys(_.groupBy(allRows, v => v.filename ?? intl('Common.None')))}},
    },
    {
      id: 'distribution',
      name: intl('VEN.Distribution'),
      resources: {distribution: {statics: Object.keys(_.groupBy(allRows, v => v.distribution ?? intl('Common.None')))}},
    },
    {
      id: 'architecture',
      name: intl('VEN.Architecture'),
      resources: {architecture: {statics: Object.keys(_.groupBy(allRows, v => v.architecture ?? intl('Common.None')))}},
    },
    {
      id: 'osVersion',
      name: intl('VEN.OSVersion'),
      resources: {osVersion: {statics: Object.keys(_.groupBy(allRows, v => v.osVersion ?? intl('Common.None')))}},
    },
    {
      id: 'ven_type',
      name: intl('VEN.EnforcementNodeType'),
      resources: {
        ven_type: {
          statics: [intl('VEN.ServerOnly'), intl('VEN.EndpointOnly'), intl('VEN.ServerEndpoint')],
        },
      },
    },
  ];
});

export const getFilterMap = createSelector([categories], categories => getAllResourcesObject(categories));

export const getFilters = state => getUrlFilterParam(state, {settings: getGridSettings, filterMap: getFilterMap});

export const getVensForGrid = createSelector([getAllVens, getFilters], (vens, filters) => {
  if (filters.isEmpty) {
    return vens;
  }

  const filterStrings = filterToQuery(filters.valid);

  return vens.filter(ven =>
    Object.keys(filterStrings).every(prop =>
      _.isNil(ven[prop]) ? filterStrings[prop] === intl('Common.None') : ven[prop] === filterStrings[prop],
    ),
  );
});

const getGrid = state =>
  getGridSelector(state, {
    settings: getGridSettings,
    rows: getVensForGrid,
    filterMap: getFilterMap,
  });

export const getVensPage = createSelector(
  [getGrid, categories, getAllVens, getVensForGrid],
  (grid, categories, vens, fileredVens) => {
    const showErrorNotification = grid.rows.some(row => row.release.startsWith(__ANTMAN__ ? '22.3.0-9540' : '22.3.'));

    return {
      grid,
      count: {
        total: vens.length,
        matched: fileredVens.length,
      },
      showErrorNotification,
      categories,
    };
  },
);
