/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import apiSaga from 'api/apiSaga';
import {call, select, put} from 'redux-saga/effects';
import * as UserSelectors from '../UserState';

export function* fetchProfile() {
  // Make sure we fetched all user to get some profile data
  const userId = yield select(UserSelectors.getUserId);

  yield call(apiSaga, 'users.get_instance', {
    params: {id: userId},
    *afterFetch({data}) {
      // update "users" and 'user' redux state
      yield put({type: 'USER_UPDATE_PROFILE', data});
    },
  });
}

export function* updateUserProfile(payloads) {
  const user = yield select(UserSelectors.getUser);

  yield call(apiSaga, 'user.update', {
    params: {id: user.id},
    data: payloads,
    *afterFetch({data}) {
      // Update the state.user and state.users.all redux store
      yield put({type: 'USER_UPDATE_PROFILE', data});
    },
  });
}
