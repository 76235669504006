/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import {Button, Checkbox, Icon, type IconName, MenuItem} from 'components';
import {mixThemeWithProps, type ThemeProps} from '@css-modules-theme/react';
import styles from './MapToolBarButton.css';
import tooltipStyles from 'components/Tooltip/Tooltip.css';
import type {ButtonProps, ButtonMenuProps} from 'components/Button/Button';
import Tippy, {type TippyProps} from '@tippyjs/react/headless';
import {
  type CSSProperties,
  forwardRef,
  type MutableRefObject,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import cx from 'classnames';
import type {State, Modifier} from '@popperjs/core';
import stylesUtils from 'utils.css';
import {domUtils, tidUtils, typesUtils} from '@illumio-shared/utils';
import type {Instance, Placement} from 'tippy.js';

const cssVariables = domUtils.getCSSVariables();
export const dropdownElementZIndex = parseInt(cssVariables['--map-toolbar-z-index'], 10);

export type MenuOption = {value: string; label: string; tid: string; name: string; subLabel?: string};
export type MenuOptions = {[key: string]: MenuOption};
export type OptionIcons = {[value: string]: IconName};

export type MenuChangeEventHandlers = {[key: string]: () => void};

export type MapToolBarButtonProps = ThemeProps & {
  mode: 'button' | 'custom-dropdown' | 'menu';
  children?: typesUtils.ReactStrictNode;
};

export type MapToolBarBaseButtonProps = ButtonProps & MapToolBarButtonProps;

export type MenuChangeHandler = (value: string) => void;
export type MapToolBarMenuButtonProps = ButtonMenuProps &
  MapToolBarButtonProps & {
    value: string;
    menuOptions: MenuOptions;
    optionIcons?: OptionIcons;
    onChange?: MenuChangeHandler;
  };

export type MapToolBarCustomDropdownProps = ButtonProps &
  MapToolBarButtonProps & {
    content?: typesUtils.ReactStrictNode;
    renderContent?: () => typesUtils.ReactStrictNode;
    tippyProps: TippyProps;
    tippyElementStyles: CSSProperties;
    onOpen?: () => void;
    onClose?: () => void;
    toolbarTid?: string;
  };
export type MapToolBarButtonHandle = {
  close?: () => void;
};

const delayDefault: [number, number] = [550, 200];

function Tooltip({
  tid,
  content,
  reference,
  maxWidth,
  light,
  delay = delayDefault,
}: TippyProps & {
  light?: boolean;
  tid?: string;
  content: typesUtils.ReactStrictNode;
  reference: MutableRefObject<HTMLSpanElement | null>;
  maxWidth?: number;
}) {
  const renderTooltipContent = useCallback(
    (attributes: {'data-placement': Placement; 'data-reference-hidden'?: string; 'data-escaped'?: string}) => (
      <div
        {...attributes}
        style={{maxWidth}}
        tabIndex={-1}
        className={cx(tooltipStyles.tooltip, {
          [tooltipStyles.light]: light,
          [tooltipStyles.dark]: !light,
          [tooltipStyles.top]: attributes['data-placement']?.startsWith('top'),
          [tooltipStyles.right]: attributes['data-placement']?.startsWith('right'),
          [tooltipStyles.bottom]: attributes['data-placement']?.startsWith('bottom'),
          [tooltipStyles.left]: attributes['data-placement']?.startsWith('left'),
        })}
      >
        <div className={tooltipStyles.content} data-tid={tidUtils.getTid('tooltip', tid)}>
          {content}
        </div>

        <div data-popper-arrow="" className={tooltipStyles.arrow} />
      </div>
    ),
    [content, maxWidth, tid, light],
  );

  return reference.current ? (
    <Tippy arrow delay={delay} render={renderTooltipContent} reference={reference.current} />
  ) : null;
}

function MapToolBarBaseButton(props: MapToolBarBaseButtonProps): JSX.Element {
  const {theme, children, tooltip, tooltipProps, ...buttonProps} = mixThemeWithProps(styles, props);
  const wrapper = useRef<HTMLSpanElement>(null);
  const buttonEnabled = !buttonProps.disabled && !buttonProps.insensitive;
  const tooltipEnabled = tooltip && wrapper.current && buttonEnabled;

  return (
    <span className={theme.toolBarButton} ref={wrapper}>
      <Button theme={theme} color="standard" {...buttonProps}>
        {children}
      </Button>

      {tooltipEnabled && <Tooltip content={tooltip} reference={wrapper} appendTo={wrapper.current} />}
    </span>
  );
}

function MapToolBarButtonMenu(props: MapToolBarMenuButtonProps): JSX.Element {
  const {theme, children, menu, menuOptions, optionIcons, value, onChange, ...buttonMenuProps} = mixThemeWithProps(
    styles,
    props,
  );

  const changeEventHandlers = useMemo(
    () =>
      menuOptions &&
      Object.entries(menuOptions).reduce(
        (handlers: MenuChangeEventHandlers, [key, option]) =>
          Object.assign(handlers, {
            [key]: () => option.value !== value && onChange?.(option.value),
          }),
        {},
      ),
    [menuOptions, value, onChange],
  );

  const menuItems = useMemo(
    () =>
      menuOptions &&
      Object.entries(menuOptions).map(([key, option]) =>
        getDropdownMenuItem({
          key,
          option,
          optionIcon: optionIcons?.[option.value],
          checked: value === option.value,
          onChange: changeEventHandlers[key],
        }),
      ),
    [value, changeEventHandlers, menuOptions, optionIcons],
  );

  return (
    <span className={theme.toolBarButton}>
      <Button.Menu menu={menuItems ?? menu} theme={theme} color="standard" {...buttonMenuProps}>
        {children}
      </Button.Menu>
    </span>
  );
}

export function getDropdownMenuItem(
  props: ThemeProps & {
    key: string;
    option: MenuOption;
    optionIcon?: IconName;
    checked: boolean;
    onChange: MenuChangeHandler;
  },
): JSX.Element {
  const {key, option, optionIcon, checked, onChange, theme} = mixThemeWithProps(styles, props);
  const handleMenuOptionChange = () => {
    onChange(option.value);
  };

  return (
    <MenuItem
      theme={theme}
      tid={`map-toolbar-button-menu-item-${option.tid}`}
      key={key}
      data={option}
      text={
        <Checkbox
          theme={theme}
          tid={`map-toolbar-button-menu-item-checkbox-${option.tid}`}
          value={option.value}
          name={option.name}
          iconOn="check"
          iconOff={null}
          notChangeable
          label={
            optionIcon ? (
              <span
                className={cx(
                  theme.optionLabel,
                  stylesUtils.gapSmall,
                  stylesUtils.gapHorizontalWrap,
                  stylesUtils.centerFlexAlign,
                )}
              >
                <Icon name={optionIcon} theme={theme} themePrefix="options-" />
                {option.label}
              </span>
            ) : (
              option.label
            )
          }
          subLabel={option.subLabel}
          checked={checked}
          labelProps={{tid: `map-toolbar-button-menu-item-label-${option.tid}`}}
        />
      }
      value={option.value}
      // eslint-disable-next-line react/jsx-no-bind
      onSelect={handleMenuOptionChange}
    />
  );
}

const MapToolBarButtonCustomDropdown = forwardRef<MapToolBarButtonHandle, MapToolBarCustomDropdownProps>(
  (props, ref): JSX.Element => {
    const {
      theme,
      renderContent,
      children,
      tippyProps,
      tippyElementStyles,
      onOpen,
      onClose,
      toolbarTid,
      ...buttonProps
    } = mixThemeWithProps(styles, props);
    const element = useRef<HTMLSpanElement | null>(null);
    const tippyInstance = useRef<Instance | undefined>();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const handleShow = useCallback(() => {
      setDropdownOpen(true);
      onOpen?.();
    }, [onOpen]);
    const handleHide = useCallback(() => {
      setDropdownOpen(false);
      onClose?.();
    }, [onClose]);
    const handleMount = useCallback(
      (instance: Instance) => {
        tippyInstance.current = instance;
      },
      [tippyInstance],
    );

    useImperativeHandle(ref, () => ({
      close: () => {
        tippyInstance.current?.hide();
      },
    }));

    const popperOptions = useMemo(
      () =>
        tippyElementStyles
          ? {
              modifiers: [
                {
                  name: 'applyStyles',
                  enabled: true,
                  phase: 'write',
                  fn: ({state}: {state: State}): State => {
                    Object.assign(state.elements.popper.style, {
                      top: `${state.rects.reference.y + state.rects.reference.height}px`,
                      ...tippyElementStyles,
                    });

                    return state;
                  },
                } as Partial<Modifier<unknown, Record<string, unknown>>>,
              ],
            }
          : undefined,
      [tippyElementStyles],
    );

    return (
      <>
        <span className={cx(theme.toolBarButton, {[theme.open]: dropdownOpen})} data-tid={toolbarTid} ref={element}>
          <Button theme={theme} color="standard" icon="down" iconAfterText {...buttonProps}>
            {children}
          </Button>
        </span>
        <Tippy
          offset={[0, 0]}
          interactive
          trigger="click"
          hideOnClick
          reference={element}
          render={renderContent}
          placement="bottom-end"
          zIndex={dropdownElementZIndex}
          popperOptions={popperOptions}
          onShow={handleShow}
          onHide={handleHide}
          onMount={handleMount}
          {...tippyProps}
        />
      </>
    );
  },
);

const MapToolBarButton = forwardRef<
  MapToolBarButtonHandle,
  MapToolBarBaseButtonProps | MapToolBarCustomDropdownProps | MapToolBarMenuButtonProps
>((props, ref): JSX.Element => {
  const {mode, theme, children, ...buttonProps} = mixThemeWithProps(styles, props);

  switch (mode) {
    case 'menu':
      return (
        <MapToolBarButtonMenu theme={theme} {...(buttonProps as MapToolBarMenuButtonProps)}>
          {children}
        </MapToolBarButtonMenu>
      );
    case 'custom-dropdown':
      return (
        <MapToolBarButtonCustomDropdown ref={ref} theme={theme} {...(buttonProps as MapToolBarCustomDropdownProps)}>
          {children}
        </MapToolBarButtonCustomDropdown>
      );
    case 'button':
      return (
        <MapToolBarBaseButton theme={theme} {...(buttonProps as MapToolBarBaseButtonProps)}>
          {children}
        </MapToolBarBaseButton>
      );
  }

  return (
    <MapToolBarBaseButton theme={theme} {...(buttonProps as MapToolBarBaseButtonProps)}>
      {children}
    </MapToolBarBaseButton>
  );
});

export default MapToolBarButton;
