/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import intl from '@illumio-shared/utils/intl';
import {hrefUtils} from '@illumio-shared/utils';

export default {
  reportTemplateList(state = [], action) {
    switch (action.type) {
      case 'REPORTTEMPLATELIST_GET_LIST':
        return action.data.list;
      default:
        return state;
    }
  },
  reportTemplateCount(state = {}, action) {
    switch (action.type) {
      case 'REPORTTEMPLATELIST_GET_LIST':
        return action.data.count;
      default:
        return state;
    }
  },
};

export const getReportTemplates = state => state.reporting.reportTemplateList;
export const getReportTemplateCount = state => state.reporting.reportTemplateCount;

// For new reports, add them here to map the intl title/label, and set the menu order
export const getTemplateList = createSelector([], () => [
  {
    title: intl('Reports.ExecutiveSummaryReport'),
    label: intl('Reports.ExecutiveSummary'),
    value: 'executive_summary_report',
    tid: 'executive-summary',
  },
  {
    title: intl('Reports.AppGroupSummaryReport'),
    label: intl('Reports.AppGroupSummary'),
    value: 'traffic_flow_report',
    tid: 'appgroup-summary',
  },
  {
    title: intl('Reports.TrafficExport'),
    label: intl('Reports.TrafficExport'),
    value: 'explorer_report',
    tid: 'explorer-report',
  },
  {
    title: intl('Reports.RuleHitCountReport'),
    label: intl('Reports.RuleHitCount'),
    value: 'rule_hit_count_report',
    tid: 'rule-hit-count',
  },
]);

export const getReportTemplatesItems = createSelector(
  [getReportTemplates, getTemplateList],
  (templates, templateList) =>
    templateList.reduce((result, item) => {
      const template = templates.find(template => hrefUtils.getId(template.href) === item.value);

      // If this item is enabled in the template list add it to the final list
      if (template?.enabled) {
        result.push({...template, ...item});
      }

      return result;
    }, []),
);
