/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import {combineReducers} from 'redux';

export const getPanelOpen = state => state.map?.panel?.panelOpen;
export const getPanelResizing = state => state.map?.panel?.panelResizing;
export const getViewWorkloadsWithTraffic = state => state.map?.panel?.viewWorkloadsWithTraffic;
export const getViewContainersWithTraffic = state => state.map?.panel?.viewContainersWithTraffic;
export const getViewVirtualServicesWithTraffic = state => state.map?.panel?.viewVirtualServicesWithTraffic;
export const getViewVirtualServersWithTraffic = state => state.map?.panel?.viewVirtualServersWithTraffic;

export default {
  panel: combineReducers({
    panelOpen(state = false, action) {
      switch (action.type) {
        case 'MAP_PANEL_OPENED':
          return true;
        case 'MAP_PANEL_CLOSED':
          return false;
        default:
          return state;
      }
    },

    panelResizing(state = false, action) {
      switch (action.type) {
        case 'MAP_PANEL_RESIZE_STARTED':
          return true;
        case 'MAP_PANEL_RESIZE_COMPLETED':
          return false;
        default:
          return state;
      }
    },

    viewWorkloadsWithTraffic(state = true, action) {
      switch (action.type) {
        case 'MAP_VIEW_WORKLOADS_WITH_TRAFFIC':
          return true;
        case 'MAP_VIEW_ALL_GROUP_WORKLOADS':
          return false;
        default:
          return state;
      }
    },

    viewContainersWithTraffic(state = true, action) {
      switch (action.type) {
        case 'MAP_VIEW_CONTAINERS_WITH_TRAFFIC':
          return true;
        case 'MAP_VIEW_ALL_GROUP_CONTAINERS':
          return false;
        default:
          return state;
      }
    },

    viewVirtualServicesWithTraffic(state = true, action) {
      switch (action.type) {
        case 'MAP_VIEW_VIRTUAL_SERVICES_WITH_TRAFFIC':
          return true;
        case 'MAP_VIEW_ALL_GROUP_VIRTUAL_SERVICES':
          return false;
        default:
          return state;
      }
    },

    viewVirtualServersWithTraffic(state = true, action) {
      switch (action.type) {
        case 'MAP_VIEW_VIRTUAL_SERVERS_WITH_TRAFFIC':
          return true;
        case 'MAP_VIEW_ALL_GROUP_VIRTUAL_SERVERS':
          return false;
        default:
          return state;
      }
    },
  }),
};
