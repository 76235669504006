/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import _ from 'lodash';
import {
  getAggregatedDraftPolicy,
  type PolicyOrder,
  type VulnerabilityPolicyDecision,
  type PolicyDecision,
} from '../../MapPolicyUtils';
import {createManagedEndpoint, getManagedEndpoint} from './MapGraphManagedEndpointUtils';
import {getLink} from './MapGraphLinkUtils';
import type {Label} from 'illumio';
import {
  isManagedEndpoint,
  type EndpointType,
  type LinkData,
  type ViewType,
  type EndType,
  type UnmanagedEndpointType,
  type ManagedDetails,
  type MapGroupingSettings,
  type MapGrouping,
  type ManagedEnpointDetails,
} from '../../MapTypes';
import type {
  Items,
  ComboId,
  LabelType,
  ComboItems,
  GraphLink,
  ComboMapping,
  GraphCombo,
  GraphCombosAndManagedEndpoints,
  GraphSelection,
  GraphManagedEndpoint,
  ChartItem,
  GraphCombos,
  GraphLinks,
  ComboVulnerabilities,
  VulnerabilityInstances,
  AggregatedVulnerabilities,
  VulnerabilityData,
  VulnerabilityPolicy,
  VulnerabilityOccurence,
  GraphServices,
  GraphVulnerabilityItems,
} from '../MapGraphTypes';
import {
  comboNodeBorderWidth,
  comboNodeDonutWidth,
  comboNodeSizeBase,
  getUnmanagedNodeSize,
  managedNodeBorderWidth,
  managedNodeSizeBase,
  nodeSizeBase,
  unmanagedNodeBorderWidth,
} from 'containers/IlluminationMap/Graph/Utils/MapGraphStyleUtils';
import {Chart} from 'regraph';
import type {RefObject} from 'react';
import type {IconName} from 'components';
import {webStorageUtils} from '@illumio-shared/utils';
import {roundNumber, getVulnerabilityByScore} from 'components/Vulnerability/VulnerabilityUtils';
import {getAppGroupId, getAppGroupName} from 'containers/IlluminationMap/Utils/MapTrafficQueryResponseUtils';
import {getUnmanagedEndpoint} from './MapGraphUnmanagedEndpointUtils';

export const DELETED_COMBO_ID = '_nodes_deleted';

export const getEmptyLabelText = (key: string, labelTypes: LabelType[], parentLabels: Label[] = []): string => {
  // If the label was empty but there was a corresponding parent, show it.
  // This might happen if they add 'appGroup' on top of 'application'
  const parentLabel = (parentLabels || []).find(label => label.key === key);

  if (parentLabel && parentLabel.id && parentLabel.id !== 'discovered') {
    return parentLabel.value;
  }

  return `${intl('Common.No')} ${
    labelTypes.find(label => label.key === key)?.display_name ||
    (key === 'appGroup' && intl('Common.AppGroup')) ||
    intl('Common.Label')
  }`;
};

export const truncateLabel = (label: string, desiredLabelLength: number): string => {
  label = (typeof label === 'string' ? label : '').trim();

  if (label.length > desiredLabelLength + 4) {
    return `${label.slice(0, desiredLabelLength / 2)}...${label.slice((-1 * desiredLabelLength) / 2, label.length)}`;
  }

  return label;
};

export const isDeletedId = (id: ComboId): boolean => id === DELETED_COMBO_ID;

export const getViewBasedEndKey = (viewType: ViewType, href: string, end: EndType): string => {
  if (viewType === 'bidirectional') {
    return href;
  }

  if (viewType === 'directional') {
    return `${href}_${end}`;
  }

  if (viewType === 'focused') {
    return end === 'focused' ? href : `${href}_${end}`;
  }

  return '';
};

export const getViewBasedEnd = (
  viewType: ViewType,
  focusedAppGroup: string,
  endpoint: ManagedDetails,
  end: EndpointType,
): EndType => {
  if (viewType === 'directional') {
    return end;
  }

  if (viewType === 'focused' && focusedAppGroup !== endpoint.appGroupId) {
    return end;
  }

  return 'focused';
};

export const keepNoAppGroupEndpoints = (endpoint: ManagedDetails, viewType: ViewType): boolean => {
  if (viewType === 'focused' && endpoint.appGroup === 'No App Group') {
    return false;
  }

  return true;
};

// Create Map Graph managed endpoints from workloads without traffic
export const calculateBaseManagedEndpoints = (
  endpoints: ManagedEnpointDetails[],
  labelTypes: LabelType[],
  appGroupLabelTypes: string[],
): {[key: string]: GraphManagedEndpoint} => {
  const initialItems: {[key: string]: GraphManagedEndpoint} = {};

  const items = endpoints.reduce((result, item: ManagedEnpointDetails) => {
    const appGroup = getAppGroupName(item.labelObject, appGroupLabelTypes);
    const appGroupId = getAppGroupId(item.labelObject, appGroupLabelTypes);
    const endpoint = {...item, appGroup, appGroupId};
    const managedKey = item.href || 'deleted';

    result[managedKey] = createManagedEndpoint(endpoint, labelTypes);

    return result;
  }, initialItems);

  return items;
};

// Data for the Graph
export const calculateGraphItems = (
  links: LinkData[],
  filteredLinks: LinkData[],
  labelTypes: LabelType[],
  viewType: ViewType,
  focusedId: ComboId,
  grouping: MapGrouping,
  appGroupLabelTypes: string[],
  commonLabels: string[],
  endpoints: ManagedEnpointDetails[],
  showAllMembers: boolean,
): Items => {
  const baseManagedEndpoints = showAllMembers
    ? calculateBaseManagedEndpoints(endpoints, labelTypes, appGroupLabelTypes)
    : {};

  const initialItems: Items = {managedEndpoints: {...baseManagedEndpoints}, unmanagedEndpoints: {}, links: {}};
  const hrefs: Record<string, {source: string; target: string}> = {};
  const linkEnds: EndpointType[] = ['source', 'target'];
  const focusedAppGroup = focusedId?.replace('_appGroup_', '');
  const removeLinks = new Set();

  const items = links.reduce((result, link) => {
    linkEnds.forEach((end: EndpointType) => {
      const linkEndpoint = link[end];

      if (isManagedEndpoint(linkEndpoint)) {
        const endpoint: ManagedDetails = linkEndpoint.details;
        const viewBasedEnd = getViewBasedEnd(viewType, focusedAppGroup, endpoint, end);
        const viewBasedManagedEndKey = getViewBasedEndKey(
          viewType,
          linkEndpoint.details.href || 'deleted',
          viewBasedEnd,
        );
        const viewBasedIp = viewType === 'directional' ? `${link[end].ip}_${end}` : link[end].ip;

        if (!hrefs[link.linkKey || '']) {
          hrefs[link.linkKey || ''] = {source: '', target: ''};
        }

        hrefs[link.linkKey || ''][end] = viewBasedManagedEndKey;

        // Delete the base managed endpoint if it's part of the traffic links.
        if (result.managedEndpoints[endpoint.href]) {
          delete result.managedEndpoints[endpoint.href];
        }

        if (keepNoAppGroupEndpoints(endpoint, viewType)) {
          if (result.managedEndpoints[viewBasedManagedEndKey]) {
            result.managedEndpoints[viewBasedManagedEndKey].ips.add(viewBasedIp);
          } else {
            result.managedEndpoints[viewBasedManagedEndKey] = getManagedEndpoint(
              endpoint,
              link[end].type,
              viewBasedIp,
              labelTypes,
              viewBasedEnd,
            );
          }
        } else {
          removeLinks.add(link.key);
        }
      } else {
        const viewBasedUnmanagedKey = viewType === 'directional' ? `${link[end].type}_${end}` : link[end].type;

        if (!hrefs[link.linkKey || '']) {
          hrefs[link.linkKey || ''] = {source: '', target: ''};
        }

        hrefs[link.linkKey || ''][end] = viewBasedUnmanagedKey;

        result.unmanagedEndpoints[viewBasedUnmanagedKey] = getUnmanagedEndpoint(
          link[end].type as UnmanagedEndpointType,
          result.unmanagedEndpoints[hrefs[link.linkKey || ''][end]],
          link,
          end,
          viewType,
        );
      }
    });

    return result;
  }, initialItems);

  items.links = filteredLinks.reduce(
    (result, link) => {
      if (!removeLinks.has(link.linkKey)) {
        const linkHref = Object.values(hrefs[link.linkKey || '']).join(';');

        result[linkHref] = getLink(
          result[linkHref],
          link,
          hrefs[link.linkKey || ''],
          grouping,
          appGroupLabelTypes,
          commonLabels,
        );
      }

      return result;
    },
    {} as Record<string, GraphLink>,
  );

  return items;
};

export const calculateGraphItemRules = (items: Items, links: LinkData[]): Items => {
  const itemsWithRules = {...items};

  links.forEach(link => {
    const itemLink = itemsWithRules.links[link.graphKey];
    const itemLinkService = itemLink?.services[link.serviceKey];

    if (itemLink) {
      itemLink.policy.draft = getAggregatedDraftPolicy(itemLink.policy, link.policy);
    }

    if (itemLinkService) {
      itemLinkService.policy.draft = getAggregatedDraftPolicy(itemLinkService.policy, link.policy);
    }
  });

  return itemsWithRules;
};

export const getComboItems = (
  items: Items,
  combos: ComboMapping,
  closedCombos: Record<string, GraphCombo>,
  comboLinks: Record<string, GraphLink>,
  focusedComboId: string | undefined,
  openComboId: string | undefined,
): ComboItems => {
  return {
    managedEndpoints: Object.keys(items.managedEndpoints).reduce(
      (result: GraphCombosAndManagedEndpoints, endpointKey): GraphCombosAndManagedEndpoints => {
        const isPartOfCombos = combos.endpoints[endpointKey];
        const isPartOfClosedCombos = combos.endpoints[endpointKey]?.some(endpointCombo => closedCombos[endpointCombo]);
        const isAppGroupMap = Boolean(focusedComboId);
        const isFocusedOrOpenComboId = combos.endpoints[endpointKey]?.some(endpointCombo =>
          [focusedComboId, openComboId].includes(endpointCombo),
        );

        // If none of the endpoint's combos are closed, then include the endpoint itself
        if (!isPartOfCombos || (!isPartOfClosedCombos && (!isAppGroupMap || isFocusedOrOpenComboId))) {
          result[endpointKey] = items.managedEndpoints[endpointKey];
        }

        return result;
      },
      {...closedCombos},
    ),
    unmanagedEndpoints: items.unmanagedEndpoints,
    links: comboLinks,
  };
};

export const getVulnerabilityExposureScore = (aggregatedVulnerability: AggregatedVulnerabilities): number | string => {
  const vulnerabilityExposureScore = aggregatedVulnerability?.vulnerabilityExposureScore ?? 0;

  return roundNumber(vulnerabilityExposureScore);
};

export const getSortedVulnerabilityOccurence = (
  vulnerabilityOccurence: VulnerabilityOccurence,
): VulnerabilityOccurence => {
  const severityOrder = ['critical', 'high', 'medium', 'low', 'info', 'none'];

  const vulnerabilityArray = Object.entries(vulnerabilityOccurence);

  vulnerabilityArray.sort(([keyA], [keyB]) => {
    const indexA = severityOrder.indexOf(keyA);
    const indexB = severityOrder.indexOf(keyB);

    // If either key is not found in the severityOrder, treat it as the lowest priority
    if (indexA === -1) {
      return 1;
    }

    if (indexB === -1) {
      return -1;
    }

    return indexA - indexB;
  });

  const sortedVulnerabilityOccurence = Object.fromEntries(vulnerabilityArray) as VulnerabilityOccurence;

  return sortedVulnerabilityOccurence;
};

export const getVulnerabilityOccurence = (
  comboInstancesWithVulnerability: VulnerabilityInstances,
): VulnerabilityOccurence => {
  const vulnerabilityOccurence = {} as VulnerabilityOccurence;

  Object.values(comboInstancesWithVulnerability || {}).forEach(instance => {
    instance.forEach(item => {
      const vulnerabilityObj = getVulnerabilityByScore(item.severity);
      const severityDisplay = vulnerabilityObj.severity;
      const workloads = item.numWorkloads || 1;

      if (vulnerabilityOccurence[severityDisplay]) {
        vulnerabilityOccurence[severityDisplay] += workloads;
      } else {
        vulnerabilityOccurence[severityDisplay] = workloads;
      }
    });
  });

  return getSortedVulnerabilityOccurence(vulnerabilityOccurence);
};

export const getPriorityPolicyDecision = (
  vulnerabilityPolicyDecision: VulnerabilityPolicyDecision,
  servicePolicyDecision: VulnerabilityPolicyDecision,
): VulnerabilityPolicyDecision => {
  const priorityOrder = ['vulnerable', 'potentiallyBlockedVulnerable', 'notVulnerable'];

  const vulnerabilityPolicyDecisionIndex = priorityOrder.indexOf(vulnerabilityPolicyDecision);
  const servicePolicyDecisionIndex = priorityOrder.indexOf(servicePolicyDecision);

  if (vulnerabilityPolicyDecisionIndex === -1 || servicePolicyDecisionIndex === -1) {
    return 'notVulnerable';
  }

  return vulnerabilityPolicyDecisionIndex < servicePolicyDecisionIndex
    ? vulnerabilityPolicyDecision
    : servicePolicyDecision;
};

export const getVulnerabilityPolicyDecision = (policyDecision: PolicyDecision): VulnerabilityPolicyDecision => {
  switch (policyDecision) {
    case 'allowed':
    case 'allowedAcrossBoundary':
      return 'vulnerable';
    case 'potentiallyBlocked':
    case 'potentiallyBlockedByBoundary':
      return 'potentiallyBlockedVulnerable';
    case 'blockedByBoundary':
    case 'blocked':
    default:
      return 'notVulnerable';
  }
};

export const getServicesWithVulnerability = (linkWithVulnerability: GraphLink): GraphServices => {
  const services = {...linkWithVulnerability.services};
  const vulnerabilityInstances = linkWithVulnerability.vulnerabilities?.instances || [];

  const uniquePortProtocolInstances = new Set();

  vulnerabilityInstances.forEach(instance => {
    const portProtocolString = `${instance.port}, ${instance.protocol}`;

    uniquePortProtocolInstances.add(portProtocolString);
  });

  if (vulnerabilityInstances?.length !== 0) {
    const servicesWithVulnerability = Object.keys(services).reduce((result, key) => {
      const portProtocolString = `${services[key].port}, ${services[key].protocolNum}`;
      const vulnerabilityExists = uniquePortProtocolInstances.has(portProtocolString);

      if (vulnerabilityExists) {
        return {
          ...result,
          [key]: {
            ...services[key],
            policy: {
              reported: {
                ...services[key].policy.reported,
                vulnerabilityDecision: getVulnerabilityPolicyDecision(services[key].policy.reported.decision),
              },
              draft: {
                ...services[key].policy.draft,
                vulnerabilityDecision: getVulnerabilityPolicyDecision(services[key].policy.draft.decision || 'unknown'),
              },
            },
          },
        };
      }

      return {
        ...result,
        [key]: {...services[key]},
      };
    }, {});

    return servicesWithVulnerability;
  }

  return linkWithVulnerability.services;
};

export const getVulnerabilityDecisionForOverallLink = (services: GraphServices): VulnerabilityPolicy => {
  const vulnerabilityDecision = {reported: 'notVulnerable', draft: 'notVulnerable'};

  Object.values(services).forEach(service => {
    const servicePolicy = service.policy;

    if (servicePolicy.reported.vulnerabilityDecision) {
      vulnerabilityDecision.reported = getPriorityPolicyDecision(
        vulnerabilityDecision.reported as VulnerabilityPolicyDecision,
        servicePolicy.reported.vulnerabilityDecision,
      );
    }

    if (servicePolicy.draft.vulnerabilityDecision) {
      vulnerabilityDecision.draft = getPriorityPolicyDecision(
        vulnerabilityDecision.draft as VulnerabilityPolicyDecision,
        servicePolicy.draft.vulnerabilityDecision,
      );
    }
  });

  return vulnerabilityDecision as VulnerabilityPolicy;
};

export const getComboItemsWithVulnerability = (
  comboItems: ComboItems,
  detectedVulnerabilities: ComboVulnerabilities,
  linksWithVulnerability: GraphLinks,
): ComboItems => {
  return {
    managedEndpoints: Object.keys(comboItems.managedEndpoints).reduce((result, combo) => {
      const comboWithVul = detectedVulnerabilities[combo];
      const aggregatedVulnerability = comboWithVul?.aggregatedValues;
      const vulnerabilityData = {
        vesScore: getVulnerabilityExposureScore(aggregatedVulnerability),
        vulnerabilityScore: getVulnerabilityOccurence(comboWithVul?.instances),
        internetExposure: Boolean(
          aggregatedVulnerability?.wideExposure.any || aggregatedVulnerability?.wideExposure.ip_list,
        ),
        computationState: aggregatedVulnerability?.vulnerabilityComputationState,
      } as VulnerabilityData;

      return {
        ...result,
        [combo]: {
          ...comboItems.managedEndpoints[combo],
          vulnerabilityData,
        },
      };
    }, {}),
    unmanagedEndpoints: comboItems.unmanagedEndpoints,
    links: Object.keys(comboItems.links).reduce((result, link) => {
      const linkWithVulnerability = linksWithVulnerability[link];
      const servicesWithVulnerabilityDecision = getServicesWithVulnerability(linkWithVulnerability);
      const vulnerabilityDecision = getVulnerabilityDecisionForOverallLink(servicesWithVulnerabilityDecision);

      return {
        ...result,
        [link]: {
          ...comboItems.links[link],
          services: servicesWithVulnerabilityDecision,
          policy: {
            ...comboItems.links[link].policy,
            draft: {
              ...comboItems.links[link].policy.draft,
              vulnerabilityDecision: vulnerabilityDecision.draft,
            },
            reported: {
              ...comboItems.links[link].policy.reported,
              vulnerabilityDecision: vulnerabilityDecision.reported,
            },
          },
        },
      };
    }, {}),
  };
};

/**
 * Returns the approximate radius of a given node (open combos currently not supported)
 * @param zoom
 * @param type
 */
export function getNodeRadius({zoom, type}: {zoom: number; type: string}): number {
  // @zoom=1;size=1, all nodes have a 27.5px radius.
  const baseNodeRadius = 27.5;
  let nodeRadius = 0;

  switch (type) {
    case 'unmanagedEndpoint':
      // @zoom=1;size=1, unmanagedEndpoint radius measures ~27.5px
      // nodeRadius~=27.5px, borderRadius=0px, donutRadius=0px, haloRadius=0px;
      nodeRadius = baseNodeRadius * getUnmanagedNodeSize(zoom) + unmanagedNodeBorderWidth;
      break;
    case 'managedEndpoint':
      // @zoom=1;size=1, managedEndpoint radius measures ~32.5px
      // nodeRadius~=27.5px, borderRadius~=5px, donutRadius=0px, haloRadius=0px;
      nodeRadius = baseNodeRadius * managedNodeSizeBase + managedNodeBorderWidth;
      break;
    case 'node':
      // @zoom=1;size=1, node radius measures ~37.5px
      // nodeRadius~=27.5px, borderRadius~=2.5px, donutRadius~=5px, haloRadius~=2.5px;
      nodeRadius = baseNodeRadius * nodeSizeBase + comboNodeBorderWidth + comboNodeDonutWidth;
      break;
    case 'combo':
      // @zoom=1;size=1, combo radius measures ~37.5px
      // nodeRadius~=27.5px, borderRadius~=2.5px, donutRadius~=5px, haloRadius~=2.5px;
      nodeRadius = baseNodeRadius * comboNodeSizeBase + comboNodeBorderWidth + comboNodeDonutWidth;
      break;
  }

  return nodeRadius * zoom;
}

/**
 * Returns the coordinates (relative to the chart) of the nodeId.
 * @param nodeId
 * @param positions
 * @param chartRef
 */
export function getNodeCoordinates({
  nodeId,
  positions,
  chartRef,
}: {
  nodeId: string;
  positions: Chart.Positions;
  chartRef: RefObject<Chart>;
}): Chart.Position | undefined {
  if (nodeId && positions && positions[nodeId] && chartRef?.current) {
    const {x: worldX, y: worldY} = positions[nodeId];

    return chartRef.current.viewCoordinates(worldX, worldY);
  }
}

/**
 * Returns the halfway point between the positions of nodeId1 and nodeId2
 * @param nodeId1
 * @param nodeId2
 * @param positions
 * @param chartRef
 */
export function getLinkCoordinates({
  nodeId1,
  nodeId2,
  positions,
  chartRef,
}: {
  nodeId1: string;
  nodeId2: string;
  positions: Chart.Positions;
  chartRef: RefObject<Chart>;
}): Chart.Position | undefined {
  if (!nodeId1 || !nodeId2) {
    return;
  }

  const coords1 = getNodeCoordinates({nodeId: nodeId1, positions, chartRef});
  const coords2 = getNodeCoordinates({nodeId: nodeId2, positions, chartRef});

  if (coords1 && coords2) {
    const {x: x1, y: y1} = coords1;
    const {x: x2, y: y2} = coords2;

    return {
      x: (x1 + x2) / 2,
      y: (y1 + y2) / 2,
    };
  }
}

/**
 * Returns coordinates of an id (node or link) from the graph
 * @param nodeId1
 * @param nodeId2
 * @param positions
 * @param chartRef
 */
export function getCoordinates({
  id,
  positions,
  chartRef,
}: {
  id?: string;
  chartRef: RefObject<Chart>;
  positions: Chart.Positions;
}): Chart.Position | undefined {
  if (id) {
    const ids = id.split(';');

    return ids.length < 2
      ? getNodeCoordinates({nodeId: ids[0], positions, chartRef})
      : getLinkCoordinates({nodeId1: ids[0], nodeId2: ids[1], positions, chartRef});
  }
}

export const getLinkIconName = (
  number: number | undefined,
  policy: string,
  providerConsumerOrder: PolicyOrder,
): IconName => {
  const orderSequence = providerConsumerOrder === 'consumerFirst' ? 1 : 0;

  if (policy === 'blocked' || policy === 'potentiallyBlocked' || policy === 'allowed') {
    if (number === orderSequence) {
      // Provider <- Consumer
      return 'map-link-left';
    }

    // Consumer -> Provider
    return 'map-link';
  }

  if (policy === 'allowedAcrossBoundary') {
    if (number === orderSequence) {
      // Provider <- Consumer
      return 'across-enf-boundary-rtl';
    }

    // Consumer -> Provider
    return 'across-enf-boundary';
  }

  if (policy === 'blockedByBoundary' || policy === 'potentiallyBlockedByBoundary') {
    if (number === orderSequence) {
      // Provider <- Consumer
      return 'enf-boundary-rtl';
    }

    // Consumer -> Provider
    return 'enf-boundary';
  }

  return 'map-link';
};

export const getSelectionForFocusedView = (
  clickedId: string,
  focusedComboId: string,
  openComboId: string,
): GraphSelection => {
  //In focused view, when you click on connected combo
  if (clickedId === openComboId) {
    return openComboId.includes('source')
      ? {comboLink: [`${openComboId};${focusedComboId}`]}
      : {comboLink: [`${focusedComboId};${openComboId}`]};
  }

  return {combo: [clickedId]};
};

export const setGraphState = ({
  queryId,
  key,
  value,
}: {
  queryId: string;
  key: string;
  value: GraphSelection | MapGroupingSettings | string[];
}): void => {
  type GraphState = {
    autoGrouping?: MapGroupingSettings;
    grouping?: string[];
    selection?: GraphSelection;
    openCombos?: Record<string, boolean>;
    timestamp: Date;
  };

  let graphState: Record<string, GraphState> = (webStorageUtils.getItem('mapGraphState') || {}) as Record<
    string,
    GraphState
  >;

  graphState = {
    ...graphState,
    [queryId]: {
      ...graphState[queryId],
      [key]: value,
      timestamp: new Date(),
    },
  };

  webStorageUtils.setItem('mapGraphState', graphState);
};

export const appGroupMapLayout = {
  name: 'sequential',
  orientation: 'right',
  curvedLinks: false,
  stretch: 1,
  level: 'hierarchy',
  orderBy: 'sequence',
  packing: 'aligned',
  stacking: {arrange: 'none'},
  tightness: 5,
} as Chart.LayoutOptions;

export const truncateString = (text: string | undefined, desiredLabelLength: number): string => {
  if (text === undefined) {
    return '';
  }

  text = text.trim();

  if (text.length > desiredLabelLength) {
    const acceptedLength = Math.floor((desiredLabelLength - 3) / 2);

    return `${text.slice(0, acceptedLength)}...${text.slice(-acceptedLength, text.length)}`;
  }

  return text;
};

export const truncateOpenComboLabel = (label: string, viewType: ViewType, numNodes: number): string => {
  label = (typeof label === 'string' ? label : '').trim();

  const splitLabel = label.split('\n');
  const truncatedLabel = splitLabel.map(label =>
    truncateString(label, viewType === 'focused' ? 35 : _.clamp(10 * numNodes, 10, 25)),
  );

  return truncatedLabel.join('\n');
};

export const truncateComboLabelOnZoom = (label: string, zoom: number, top: boolean): string => {
  label = (typeof label === 'string' ? label : '').trim();

  const factor = Math.ceil((top ? 20 : 18) * zoom);

  const splitLabel = label.split('\n');
  const desiredLength = factor <= 11 ? 12 : factor;

  const truncatedLabel = splitLabel.map(label => truncateLabel(label, desiredLength > 50 ? 50 : desiredLength));

  return truncatedLabel.join('\n');
};

export const getComboVulnerabilityLabels = (
  combo: GraphCombo,
  labelTypes: {key: string}[],
  orgId: string,
): string[] => {
  const comboLabels = combo?.labels ?? [];
  const isMissingLabels = comboLabels.some(label => !label.href);

  if (isMissingLabels) {
    const hrefPrefix = `/orgs/${orgId}/labels`;
    const labelsObj = labelTypes.reduce((labelsObj: {[key: string]: string}, labelType) => {
      labelsObj[labelType.key] = `${hrefPrefix}?key=${labelType.key}&exists=false`;

      return labelsObj;
    }, {});

    comboLabels.forEach(label => {
      if (label.href) {
        labelsObj[label.key] = label.href as string;
      }
    });

    return Object.values(labelsObj);
  }

  return comboLabels.map(label => label.href as string);
};

export const getGraphVisibleWorkloads = ({
  chartItems,
  comboItems,
}: {
  chartItems: {[key: string]: ChartItem};
  comboItems: ComboItems;
}): string[] => {
  const {managedEndpoints} = comboItems;

  return Object.keys(chartItems).reduce((result: string[], key) => {
    const endpoint = managedEndpoints[key] as GraphManagedEndpoint | undefined;

    if (
      endpoint &&
      endpoint.managedType === 'workload' &&
      (endpoint.subType === 'workload' || endpoint.subType === 'idle' || endpoint.subType === 'unmanaged')
    ) {
      result.push(key);
    }

    return result;
  }, []);
};

export const getGraphVulnerabilityItems = ({
  combos,
  openCombos,
  closedCombos,
  labelTypes,
  chartItems,
  orgId,
  comboItems,
}: {
  combos: ComboMapping;
  openCombos: Record<string, boolean>;
  closedCombos: GraphCombos;
  chartItems: Record<string, ChartItem>;
  labelTypes: {key: string}[];
  orgId: string;
  comboItems: ComboItems;
}): {combos: {[key: string]: string[]}; endpoints: string[]} => {
  const closedComboLabels = Object.entries(closedCombos).reduce((result: {[key: string]: string[]}, [id, combo]) => {
    result[id] = getComboVulnerabilityLabels(combo, labelTypes, orgId);

    return result;
  }, {});
  const openComboLabels = Object.keys(openCombos).reduce((result: {[key: string]: string[]}, comboId) => {
    result[comboId] = getComboVulnerabilityLabels(combos.combos[comboId as ComboId], labelTypes, orgId);

    return result;
  }, {});
  const endpoints = getGraphVisibleWorkloads({chartItems, comboItems});

  return {combos: {...closedComboLabels, ...openComboLabels}, endpoints};
};

export const normalizeNodeSize = (
  endpointCount: number | undefined,
  endpointsCount: {maxCount: number; minCount: number},
): number => {
  if (endpointCount === undefined || endpointsCount.maxCount === endpointsCount.minCount) {
    return comboNodeSizeBase;
  }

  return (
    2 *
    comboNodeSizeBase *
    ((0.8 * (endpointCount - endpointsCount.maxCount)) / (endpointsCount.maxCount - endpointsCount.minCount) + 1.5)
  );
};

export const calculateMinMaxEndpoints = (
  endpoints: GraphCombosAndManagedEndpoints,
): {maxCount: number; minCount: number} => {
  return Object.values(endpoints).reduce(
    (result, endpoint): {maxCount: number; minCount: number} => {
      if (endpoint.type === 'combo') {
        const count = endpoint.endpointCount;

        if (count > result.maxCount || result.maxCount === 0) {
          result.maxCount = count;
        }

        if (count < result.minCount || result.minCount === 0) {
          result.minCount = count;
        }
      }

      return result;
    },
    {minCount: 0, maxCount: 0} as {minCount: number; maxCount: number},
  );
};

export const graphVulnerabilityItemsChanged = (
  graphVulnerabilityItems: GraphVulnerabilityItems,
  previousGraphVulnerabilityItems: GraphVulnerabilityItems | undefined,
): boolean => {
  const comboKeys = Object.keys(graphVulnerabilityItems.combos);
  const endpointKeys = graphVulnerabilityItems.endpoints;
  const previousComboKeys = Object.keys(previousGraphVulnerabilityItems?.combos ?? {});
  const previousEndpointKeys = previousGraphVulnerabilityItems?.endpoints ?? [];

  return !_.isEqual(comboKeys, previousComboKeys) || !_.isEqual(endpointKeys, previousEndpointKeys);
};
