/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import {getPortAndProtocolString} from 'containers/Service/ServiceUtils';
import {
  isUnmanagedEndpoint,
  type EndpointType,
  type LinkData,
  type IPEndpoint,
  type UnmanagedEndpointType,
  type ViewType,
} from '../../MapTypes';
import type {
  GraphUnmanagedEndpoint,
  UnmanagedIpLists,
  UnmanagedFQDNs,
  UnmanagedAddresses,
  PortProtocolProcessStrings,
} from '../MapGraphTypes';

export const isIdUnmanaged = (id: string): boolean =>
  [intl('Common.IPList'), intl('PCE.FQDN'), intl('Common.PrivateAddress'), intl('Common.PublicAddress')].some(
    unmanagedType => id.includes(unmanagedType),
  );

export const aggregateServiceDetails = (
  link: LinkData,
  ip: string,
  currentServiceStrings: PortProtocolProcessStrings,
): PortProtocolProcessStrings => {
  const newServiceStrings: PortProtocolProcessStrings = {...currentServiceStrings};
  const portProtocol = getPortAndProtocolString(link.service);

  const portProtocolProcess = `${portProtocol ?? ''} ${link.service.processName ?? ''}`;

  if (link.service) {
    newServiceStrings.serviceStringSet.add(portProtocolProcess);

    newServiceStrings.addressServiceStringMap[ip] ||= {
      consumerServiceStringSet: new Set(),
      providerServiceStringSet: new Set(),
    };

    if (link.service.outbound?.processName) {
      newServiceStrings.consumerServiceStringSet.add(link.service.outbound?.processName);
      newServiceStrings.addressServiceStringMap[ip].consumerServiceStringSet.add(link.service.outbound?.processName);
      newServiceStrings.providerServiceStringSet.add(portProtocol);
      newServiceStrings.addressServiceStringMap[ip].providerServiceStringSet.add(portProtocol);
    }

    if (link.service.inbound?.processName) {
      newServiceStrings.providerServiceStringSet.add(`${portProtocol} ${link.service.inbound?.processName}`);
      newServiceStrings.addressServiceStringMap[ip].providerServiceStringSet.add(portProtocolProcess);
    }
  }

  return newServiceStrings;
};

export const getIPListEndpointDetails = (
  link: LinkData,
  linkEnd: EndpointType,
  aggregatedIpLists: UnmanagedIpLists = {},
  viewType: ViewType,
): UnmanagedIpLists => {
  const endpoint = link[linkEnd];

  if (isUnmanagedEndpoint(endpoint)) {
    const ipLists = endpoint.details.ipLists || [];
    const viewBasedIpLists = viewType === 'directional' && ipLists.length > 1 ? ipLists.slice(1) : ipLists;

    return (viewBasedIpLists || []).reduce((result: UnmanagedIpLists, list: IPEndpoint): UnmanagedIpLists => {
      const viewBasedListHref = viewType === 'directional' ? `${list.href}_${linkEnd}` : list.href;

      result[viewBasedListHref] ||= {
        type: 'ipList',
        name: list.name,
        addresses: new Set(),
        createWorkloadNames: new Set(),
        connections: 0,
        direction: link.direction,
        rules: new Set(),
        serviceStrings: {
          serviceStringSet: new Set(),
          consumerServiceStringSet: new Set(),
          providerServiceStringSet: new Set(),
          addressServiceStringMap: {},
        },
        data: {},
      };

      const viewBasedAddress = viewType === 'directional' ? `${endpoint.fullIp}_${linkEnd}` : endpoint.fullIp;
      const createWorkloadName = endpoint.details.fqdn || endpoint.ip;

      result[viewBasedListHref].connections += 1;
      result[viewBasedListHref].addresses.add(viewBasedAddress);
      result[viewBasedListHref].createWorkloadNames.add(createWorkloadName);

      result[viewBasedListHref].rules = new Set([
        ...result[viewBasedListHref].rules,
        ...(link.policy.draft.rules || []),
      ]);
      result[viewBasedListHref].serviceStrings = aggregateServiceDetails(
        link,
        viewBasedAddress,
        result[viewBasedListHref].serviceStrings,
      );

      return result;
    }, aggregatedIpLists);
  }

  return {};
};

export const getFQDNEndpointDetails = (
  link: LinkData,
  linkEnd: EndpointType,
  aggregatedFQDNs: UnmanagedFQDNs = {},
  viewType: ViewType,
): UnmanagedFQDNs => {
  const endpoint = link[linkEnd];

  if (isUnmanagedEndpoint(endpoint)) {
    const linkEndpoint = endpoint.details;
    const fqdn = linkEndpoint?.fqdn;

    if (!fqdn) {
      return aggregatedFQDNs;
    }

    const viewBasedFQDN = viewType === 'directional' ? `${fqdn}_${linkEnd}` : fqdn;

    aggregatedFQDNs[viewBasedFQDN] ||= {
      type: 'fqdn',
      name: fqdn,
      addresses: new Set(),
      createWorkloadNames: new Set(),
      workloads: new Set(),
      direction: link.direction,
      serviceStrings: {
        serviceStringSet: new Set(),
        consumerServiceStringSet: new Set(),
        providerServiceStringSet: new Set(),
        addressServiceStringMap: {},
      },
      data: {},
    };

    const connectedEndpointType = linkEnd === 'source' ? 'target' : 'source';
    const viewBasedAddress = viewType === 'directional' ? `${link[linkEnd].ip}_${linkEnd}` : link[linkEnd].ip;
    const createWorkloadName = fqdn || link[linkEnd].ip;

    aggregatedFQDNs[viewBasedFQDN].addresses.add(viewBasedAddress);
    aggregatedFQDNs[viewBasedFQDN].createWorkloadNames.add(createWorkloadName);
    aggregatedFQDNs[viewBasedFQDN].direction = link.direction;
    aggregatedFQDNs[viewBasedFQDN].workloads.add(link[connectedEndpointType].ip);
    aggregatedFQDNs[viewBasedFQDN].serviceStrings = aggregateServiceDetails(
      link,
      viewBasedAddress,
      aggregatedFQDNs[viewBasedFQDN].serviceStrings,
    );

    return aggregatedFQDNs;
  }

  return {};
};

export const getPrivateAddressEndpointDetails = (
  link: LinkData,
  linkEnd: EndpointType,
  aggregatedPrivateAddresses: UnmanagedAddresses = {},
  viewType: ViewType,
): UnmanagedAddresses => {
  const endpoint = link[linkEnd];

  if (isUnmanagedEndpoint(endpoint)) {
    const pvSubnet = endpoint.details?.privateAddressSubnet;

    if (!pvSubnet) {
      return aggregatedPrivateAddresses;
    }

    const viewBasedPvSubnet = viewType === 'directional' ? `${pvSubnet}_${linkEnd}` : pvSubnet;

    aggregatedPrivateAddresses[viewBasedPvSubnet] ||= {
      type: 'privateAddress',
      name: endpoint.fullIp,
      addresses: new Set(),
      createWorkloadNames: new Set(),
      direction: link.direction,
      serviceStrings: {
        serviceStringSet: new Set(),
        consumerServiceStringSet: new Set(),
        providerServiceStringSet: new Set(),
        addressServiceStringMap: {},
      },
      data: {},
    };

    const viewBasedAddress = viewType === 'directional' ? `${endpoint.fullIp}_${linkEnd}` : endpoint.fullIp;
    const createWorkloadName = endpoint.details.fqdn || endpoint.ip;

    aggregatedPrivateAddresses[viewBasedPvSubnet].direction = link.direction;
    aggregatedPrivateAddresses[viewBasedPvSubnet].addresses.add(viewBasedAddress);
    aggregatedPrivateAddresses[viewBasedPvSubnet].createWorkloadNames.add(createWorkloadName);

    aggregatedPrivateAddresses[viewBasedPvSubnet].serviceStrings = aggregateServiceDetails(
      link,
      viewBasedAddress,
      aggregatedPrivateAddresses[viewBasedPvSubnet].serviceStrings,
    );

    return aggregatedPrivateAddresses;
  }

  return {};
};

export const getInternetEndpointDetails = (
  link: LinkData,
  linkEnd: EndpointType,
  aggregatedInternetAddresses: UnmanagedAddresses = {},
  viewType: ViewType,
): UnmanagedAddresses => {
  const endpoint = link[linkEnd];

  if (isUnmanagedEndpoint(endpoint)) {
    const ip = endpoint.fullIp;
    const viewBasedIP = viewType === 'directional' ? `${ip}_${linkEnd}` : ip;
    const createWorkloadName = endpoint.details.fqdn || endpoint.ip;

    aggregatedInternetAddresses[viewBasedIP] ||= {
      type: 'internet',
      name: ip,
      addresses: new Set(),
      createWorkloadNames: new Set(),
      direction: link.direction,
      serviceStrings: {
        serviceStringSet: new Set(),
        consumerServiceStringSet: new Set(),
        providerServiceStringSet: new Set(),
        addressServiceStringMap: {},
      },
      data: {},
    };

    aggregatedInternetAddresses[viewBasedIP].direction = link.direction;
    aggregatedInternetAddresses[viewBasedIP].addresses.add(viewBasedIP);
    aggregatedInternetAddresses[viewBasedIP].createWorkloadNames.add(createWorkloadName);

    aggregatedInternetAddresses[viewBasedIP].serviceStrings = aggregateServiceDetails(
      link,
      viewBasedIP,
      aggregatedInternetAddresses[viewBasedIP].serviceStrings,
    );

    return aggregatedInternetAddresses;
  }

  return {};
};

export const getUnmanagedEndpoint = (
  type: UnmanagedEndpointType,
  aggregatedEndpoint: GraphUnmanagedEndpoint = {
    type: 'internet',
    name: intl('Common.PublicAddress'),
    items: {},
    endType: 'source',
  },
  link: LinkData,
  linkEnd: EndpointType,
  viewType: ViewType,
): GraphUnmanagedEndpoint => {
  if (type === 'ipList') {
    return {
      type: 'ipList',
      name: intl('Common.IPList'),
      items: getIPListEndpointDetails(link, linkEnd, aggregatedEndpoint.items as UnmanagedIpLists, viewType),
      endType: viewType === 'focused' ? 'focused' : linkEnd,
    };
  }

  if (type === 'fqdn') {
    return {
      type: 'fqdn',
      name: intl('PCE.FQDN'),
      items: getFQDNEndpointDetails(link, linkEnd, aggregatedEndpoint.items as UnmanagedFQDNs, viewType),
      endType: viewType === 'focused' ? 'focused' : linkEnd,
    };
  }

  if (type === 'privateAddress') {
    return {
      type: 'privateAddress',
      name: intl('Common.PrivateAddress'),
      items: getPrivateAddressEndpointDetails(link, linkEnd, aggregatedEndpoint.items as UnmanagedAddresses, viewType),
      endType: viewType === 'focused' ? 'focused' : linkEnd,
    };
  }

  return {
    type: 'internet',
    name: intl('Common.PublicAddress'),
    items: getInternetEndpointDetails(link, linkEnd, aggregatedEndpoint.items as UnmanagedAddresses, viewType),
    endType: viewType === 'focused' ? 'focused' : linkEnd,
  };
};
