/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {gridSettings} from './WorkloadVulnerabilitiesConfig';
import {getWorkloadInstance} from '../WorkloadItemState';

export default {
  vulnerabilities(state = {}, action) {
    switch (action.type) {
      case 'WORKLOAD_VULNERABILITIES':
        return action.data;
      default:
        return state;
    }
  },
};

export const getVulnerabilities = state => state.workload.vulnerabilities;

export const getVulnerabilitiesData = createSelector(
  [getVulnerabilities, getWorkloadInstance],
  (vulnerabilities, workload) => {
    return vulnerabilities[workload.href]
      ? Object.values(vulnerabilities[workload.href].instances)
          .flat()
          .map((vulnerability, idx) => ({
            key: idx,
            ...vulnerability,
            // traffic: traffic ? traffic[[vulnerability.port, vulnerability.protocol].join()] : intl('Common.Loading'),
          }))
      : [];
  },
);

export const getVulnerabilitiesRows = createSelector(
  [getVulnerabilitiesData],
  vulnerabilitiesData => vulnerabilitiesData,
);

export const vulnerabilitiesGridSettings = {...gridSettings};

const getVulnerabilitiesGrid = state =>
  getGridSelector(state, {
    settings: vulnerabilitiesGridSettings,
    rows: getVulnerabilitiesRows,
  });

export const getVulnerabilitiesPage = createSelector(
  [getWorkloadInstance, getVulnerabilitiesGrid],
  (workload, vulnerabilitiesgrid) => ({
    workload,
    vulnerabilitiesgrid,
  }),
);
