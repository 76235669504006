/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import * as GridUtils from 'components/Grid/GridUtils';
import {Icon} from 'components';
import {formatProProtocol, formatVulnerability} from './WorkloadVulnerabilitiesUtils';
import {createSelector} from 'reselect';

export const gridSettings = createSelector([], () => ({
  id: 'workloadvulnerability',
  sort: 'name',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    exposurescore: {
      header: intl('Vulnerability.VEScore'),
      value: ({row}) => formatVulnerability(row),
    },
    score: {
      header: intl('Vulnerability.Score'),
      value: ({row}) => row.vulnerabilityScore,
    },
    portexposure: {
      header: intl('Vulnerability.EWExposure'),
      value: ({row}) => row.vulnerablePortExposure,
      format: ({value}) => (value === null ? intl('Common.NA') : value),
    },
    wideexposure: {
      header: intl('Vulnerability.InternetExposure'),
      value: ({row}) => row.wideExposure,
      format: ({value}) => {
        if (value && value.any === null) {
          return intl('Common.NA');
        }

        return value && (value.any || value.ip_list) ? <Icon name="internet" size="medium" /> : intl('Common.None');
      },
    },
    traffic: {
      header: intl('Vulnerability.ProvidedTraffic'),
      // value: ({row}) => row.protocol,
      // format: ({value}) => portUtils.lookupProtocol(value),
    },
    port: {
      header: intl('Port.Protocol'),
      value: ({row}) => formatProProtocol(row),
    },
    protocol: {
      header: intl('Vulnerability.CVEIds'),
      value: ({row}) => row.details.cve_ids && row.details.cve_ids.map(id => <div>{id}</div>),
    },
    name: {
      header: intl('Common.Name'),
      value: ({row}) => row.details.name,
    },
  },

  templates: [
    [
      {columns: ['exposurescore'], size: 'minmax(100px, auto)'},
      {columns: ['score'], size: 'minmax(100px, auto)'},
      {columns: ['portexposure'], size: 'minmax(100px, auto)'},
      {columns: ['wideexposure'], size: 'minmax(100px, auto)'},
      {columns: ['traffic'], size: 'minmax(100px, auto)'},
      {columns: ['port'], size: 'minmax(100px, auto)'},
      {columns: ['protocol'], size: 'minmax(100px, auto)'},
      {columns: ['name'], size: 'minmax(100px, auto)'},
    ],
    {
      maxWidth: 800,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['exposurescore'], size: 'minmax(100px, auto)'},
            {columns: ['score'], size: 'minmax(100px, auto)'},
            {columns: ['portexposure'], size: 'minmax(100px, auto)'},
            {columns: ['wideexposure'], size: 'minmax(100px, auto)'},
            {columns: ['traffic'], size: 'minmax(100px, auto)'},
            {columns: ['port'], size: 'minmax(100px, auto)'},
            {columns: ['protocol'], size: 'minmax(100px, auto)'},
            {columns: ['name'], size: 'minmax(100px, auto)'},
          ];
        }

        return [
          {columns: ['exposurescore'], size: 'minmax(100px, auto)'},
          {columns: ['score'], size: 'minmax(100px, auto)'},
          {columns: ['portexposure'], size: 'minmax(100px, auto)'},
          {columns: ['wideexposure'], size: 'minmax(100px, auto)'},
          {columns: ['traffic'], size: 'minmax(100px, auto)'},
          {columns: ['port'], size: 'minmax(100px, auto)'},
          {columns: ['protocol'], size: 'minmax(100px, auto)'},
          {columns: ['name'], size: 'minmax(100px, auto)'},
        ];
      },
    },
    {
      maxWidth: 640,
      template(columns) {
        if (GridUtils.hasOptionalColumns(columns)) {
          //all column breakpoint
          return [
            {columns: ['exposurescore', 'score', 'portexposure', 'wideexposure'], size: 'minmax(200px, auto)'},
            {columns: ['traffic', 'port', 'protocol', 'name'], size: 'minmax(200px, auto)'},
          ];
        }

        return [
          {columns: ['exposurescore', 'score', 'portexposure', 'wideexposure'], size: 'minmax(200px, auto)'},
          {columns: ['traffic', 'port', 'protocol', 'name'], size: 'minmax(200px, auto)'},
        ];
      },
    },
  ],
}));
