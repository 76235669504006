/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {createSelector} from 'reselect';

export default {
  settings: (() => {
    const defaultSettings = {
      colorBlind: 'normal',
      explorerMaxResults: 5000,
      explorerMaxDownloadResults: 100_000,
      defaultPolicyVersion: 'reported',
      defaultIllumination: null,
      xpressFeatures: 'essential',
    };

    return (state = defaultSettings, action) => {
      if (action.type === 'SET_USER_SETTINGS') {
        const settings = _.defaultsDeep({}, action.data, defaultSettings);

        // Limit the Illumination Plus flows per page to 10K
        settings.explorerMaxResults = Math.min(10_000, settings.explorerMaxResults);

        return settings;
      }

      return state;
    };
  })(),
};

export const getUserSettings = state => state.user.settings;

export const getColorBlindMode = createSelector(getUserSettings, settings => settings.colorBlind);
export const getDefaultPolicyVersion = createSelector(getUserSettings, settings => settings.defaultPolicyVersion);
export const getDefaultAppMap = createSelector(getUserSettings, settings => settings.defaultAppMap);
export const getDefaultIllumination = createSelector(getUserSettings, settings => settings.defaultIllumination);
export const getXpressFeaturesMode = createSelector(getUserSettings, settings => settings.xpressFeatures);
export const isClassicIlluminationEnabled = createSelector(
  getUserSettings,
  settings => settings.classicPagesEnabled?.classicIlluminationEnabled,
);
export const isClassicExplorerEnabled = createSelector(
  getUserSettings,
  settings => settings.classicPagesEnabled?.classicExplorerEnabled,
);
