/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import {call, delay, put, select} from 'redux-saga/effects';
import {errorUtils} from '@illumio-shared/utils';
import apiSaga from 'api/apiSaga';
import {getAppGroups} from 'containers/AppGroups/AppGroupState';
import {isIlluminationApiEnabled} from 'containers/App/AppState';
import _ from 'lodash';

export function* fetchAppGroupSummary({rebuild = false, force = false} = {}) {
  let currentRebuild = rebuild;

  while (true) {
    try {
      return yield call(apiSaga, 'app_group_summary.get', {
        query: {include_stale: false},
        cache: !force,
        headers: currentRebuild ? {'cache-control': 'no-cache'} : {},
        timeout: 100_000,
        *onDone({data}) {
          if (!data) {
            // If the api returns no data, a rebuild needs to be initiated
            yield call(fetchAppGroupSummary, {rebuild: true, force: true});
          }

          const {nodes, labels} = data;

          yield put({type: 'APPGROUPSUMMARY_GET_LIST', data: {nodes, labels}});
        },
      });
    } catch (error) {
      if (error instanceof errorUtils.TimeoutError || (error instanceof errorUtils.APIError && error.timeout)) {
        currentRebuild = false;
        yield delay(30_000); // Wait a bit before trying again
      } else {
        throw error;
      }
    }
  }
}

export function* fetchAppGroupRuleCoverage() {
  while (true) {
    try {
      return yield call(apiSaga, 'app_groups.observed_rule_coverage', {
        timeout: 100_000,
        *onDone({data}) {
          yield put({type: 'APPGROUP_RULE_COVERAGE_GET_LIST', data});
        },
      });
    } catch (error) {
      if (error instanceof errorUtils.TimeoutError || (error instanceof errorUtils.APIError && error.timeout)) {
        yield delay(30_000); // Wait a bit before trying again
      } else {
        throw error;
      }
    }
  }
}

export function* prefetchAppGroupTabs() {
  const appGroups = yield select(getAppGroups);
  const illuminationApiEnabled = yield select(isIlluminationApiEnabled);

  if (illuminationApiEnabled && _.isEmpty(appGroups)) {
    yield call(fetchAppGroupSummary, {});
  }
}
